<template>
  <div class="schedule-item">
    <div class="hd cl">
      <div class="fl">
        <el-select class="select" :value="`${index}-${index+1}`" @change="swapArr" size="small" placeholder="">
          <el-option v-for="i of daysLength"
                     :key="i"
                     :label="`第${i}節`"
                     :value="`${index}-${i}`">
          </el-option>
        </el-select>
      </div>
      <a class="fr btn-del" href="javascript:;" @click="deleteDay">
        <img src="../../../assets/images/icon/ashcan.png"/>
      </a>
    </div>
    <div class="bd">
      <div class="form-item">
        <div class="label">{{$t('ItineraryTitle')}}：</div>
        <div class="value">
          <input class="ipt" type="text" maxlength="100" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="item.title"/>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{$t('ItineraryContent')}}：</div>
        <div class="value">
          <el-input type="textarea"
                    placeholder=""
                    v-model="item.content"
                    maxlength="1000"
                    show-word-limit>
          </el-input>
        </div>
      </div>
      <div class="cl">
        <div class="form-item fl">
          <div class="label">{{$t('Traffic')}}：</div>
          <div class="value">
            <input class="ipt w1" type="text" maxlength="100" autocomplete="off" :placeholder="$t('PleaseEnter')"
                   v-model.trim="item.traffic"/>
          </div>
        </div>
        <div class="form-item fr">
          <div class="label">{{$t('Breakfast')}}：</div>
          <div class="value">
            <input class="ipt w1" type="text" maxlength="100" autocomplete="off" :placeholder="$t('PleaseEnter')"
                   v-model.trim="item.breakfast"/>
          </div>
        </div>
      </div>
      <div class="cl">
        <div class="form-item fl">
          <div class="label">{{$t('Lunch')}}：</div>
          <div class="value">
            <input class="ipt w1" type="text" maxlength="100" autocomplete="off" :placeholder="$t('PleaseEnter')"
                   v-model.trim="item.chineseFood"/>
          </div>
        </div>
        <div class="form-item fr">
          <div class="label">{{$t('Dinner')}}：</div>
          <div class="value">
            <input class="ipt w1" type="text" maxlength="100" autocomplete="off" :placeholder="$t('PleaseEnter')"
                   v-model.trim="item.dinner"/>
          </div>
        </div>
      </div>
      <div class="cl">
        <div class="form-item fl">
          <div class="label">{{$t('Lodging')}}：</div>
          <div class="value">
            <input class="ipt w1" type="text" maxlength="100" autocomplete="off" :placeholder="$t('PleaseEnter')"
                   v-model.trim="item.room"/>
          </div>
        </div>
        <div class="form-item fr">
          <div class="label">{{$t('Other')}}：</div>
          <div class="value">
            <input class="ipt w1" type="text" maxlength="100" autocomplete="off" :placeholder="$t('PleaseEnter')"
                   v-model.trim="item.other"/>
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{$t('Image')}}：</div>
        <div class="value">
          <el-upload class="upload-list"
                     :file-list="item.fileList"
                     action="/v2/admin/common/file"
                     list-type="picture-card"
                     :limit="4"
                     accept=".jpg, .jpeg"
                     :on-change="handleChange"
                     :on-remove="handleRemove"
                     :on-exceed="handleExceed"
                     :on-success="handleFileSuccess">
            <div class="item btn-upload" slot="default">
              <img src="../../../assets/images/icon/upload-40.png"/>
            </div>
            <div slot="tip" class="upload-tip">{{$t('Upload photos, only supports .JPG format')}}</div>
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ScheduleItem',
    props: {
      index: Number,
      item: Object,
      daysLength: Number
    },
    data() {
      return {};
    },
    methods: {
      swapArr(day) {
        this.$emit('swap-arr', day);
      },
      deleteDay() {
        this.$emit('delete-day', this.index);
      },
      handleChange(file, fileList) {
        this.item.fileList = fileList;
      },
      handleExceed(files, fileList) {
        this.$message.warning('上限4张图片');
      },
      handleRemove(file, fileList) {
        let index;
        for (let i in this.item.fileList) {
          if (this.item.fileList[i].uid === file.uid) {
            index = i;
            break;
          }
        }
        this.item.fileList.splice(index, 1);
      },
      handleFileSuccess(res, file) {
        const {code, value} = res;
        if (code === 200) {
          for (let i in this.item.fileList) {
            if (this.item.fileList[i].uid === file.uid) {
              this.item.fileList[i].url = value.url;
              break;
            }
          }
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .schedule-item{
    margin-top:30px;padding:0 20px;background:#f4f4f4;
    .hd{padding:16px 0;border-bottom:1px dashed #e0e0e0;}
    .btn-del{margin-top:8px;}
  }
  .form-item{
    display:flex;margin-top:24px;
    .label{min-width:80px;line-height:32px;}
    .value{flex:1;}
    .ipt{
      width:100%;height:32px;padding:0 10px;color:#321908;font-size:14px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
      &.w1{width:300px;}
    }
    .upload-list{
      padding-bottom:16px;
      /deep/ .el-upload--picture-card,
      /deep/ .el-upload-list--picture-card .el-upload-list__item{width:60px;height:60px;margin-bottom:8px;line-height:1;border:none;vertical-align:top;background-color:#fff;border-radius:0;}
      .item{width:100%;height:100%;}
      .btn-upload{padding:10px;}
      .upload-tip{display:inline-block;padding-left:4px;line-height:60px;white-space:nowrap;color:#999;font-size:14px;}
    }
  }
</style>
