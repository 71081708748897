<template>
  <div class="self-funded">
<!--    <div class="m-t">-->
<!--      <froala :config="froalaConfig" v-model="content"/>-->
<!--    </div>-->
<!--    <div  id="editor"></div>-->
    <editorElem v-model="content"/>
    <slot></slot>
    <div class="actions">
      <button class="button button-primary" type="button" :disabled="!content" @click="onSubmit">{{$t('Save')}}</button>
      <button class="button" type="button" @click="onNext">{{$t('NextStep')}}</button>
    </div>
  </div>
</template>
<script>
  import editor from '../../../mixins/editor';
  import {saveProductCommon, getProductCommonDetail} from '../../../api/user-product';
  import editorElem from '../../../components/EditorBar';
  export default {
    name: 'SelfFunded',
    props: {
      productId: String,
      language: String,
      lineId: String,
      programLabel: String,
      programName: String
    },
    data() {
      return {
        currentId: '',
        content: '',
        oldContent: '',
        editor: ''
      };
    },
    components: {editorElem},
    mixins: [editor],
    created() {
      this.getDetail();
    },
    destroyed() {
      if (this.content !== this.oldContent) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.UnsavedPrompt'),
          confirmButtonText: this.$t('Save'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              this.onSubmit(() => {
                instance.confirmButtonLoading = false;
                done();
              }, true);
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      }
    },
    methods: {
      // 获取详情
      getDetail() {
        getProductCommonDetail(this.language, this.lineId, this.programLabel).then(res => {
          const value = res.value || {};
          this.currentId = value.currentId || '';
          this.content = value.content || '';
          this.oldContent = this.content;
        });
      },
      // 储存
      onSubmit(callback, isForcedSave) {
        const data = {
          productId: this.productId,
          lineId: this.lineId,
          languageType: this.language,
          currentId: this.currentId,
          programLabel: this.programLabel,
          programName: this.programName,
          isCustomize: 'N',
          content: this.content
        };

        saveProductCommon(data).then(res => {
          this.oldContent = this.content;
          if (!this.currentId) {
            this.currentId = res.value.currentId || '';
          }
          !isForcedSave && this.$message.success(this.$t('SaveSuccessfully'));
          if (typeof callback === 'function') {
            callback();
          }
        });
      },
      // 下一步
      onNext() {
        this.onSubmit(() => {
          this.$parent.nextStep();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .self-funded{
    .ipt{
      width:100%;height:60px;padding:0 20px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    /deep/ .el-textarea .el-textarea__inner{height:342px;padding:20px;}
  }
</style>
