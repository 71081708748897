<template>
  <div class="column-tabs-nav">
    <a class="btn-setting" href="javascript:;" @click="()=>$refs.selectColumnModal.show()">
      <i class="icons i-setting"></i>
      <span class="vam">{{$t('Setting')}}</span>
    </a>
    <div class="wrap">
      <a class="btn-prev" :disabled="translateX===0" @click="onPrevPage" v-if="totalWidth>wrapWidth"></a>
      <div class="inner" ref="tabListWrap">
        <div class="tab-list" :style="{transform:`translateX(${translateX}px)`}" ref="tabList">
          <a class="tab-item"
             v-for="item of tabList"
             :class="{active:item.programLabel===current}"
             :key="item.id"
             @click="setCurrent(item.programLabel)">
            {{item.programName}}
          </a>
        </div>
      </div>
      <a class="btn-next" :disabled="translateX===-(totalWidth-wrapWidth)" @click="onNextPage"
         v-if="totalWidth>wrapWidth"></a>
    </div>
    <select-column-modal :language="language"
                         :product-id="productId"
                         :line-id="lineId"
                         :selected-column-list="tabList"
                         :default-column="defaultColumn"
                         @reload-list="getProductLineColumn"
                         ref="selectColumnModal"/>
  </div>
</template>
<script>
  import SelectColumnModal from './SelectColumnModal';
  import {getProductLineColumn, getProductColumnList, saveProductLineColumn} from '../../../api/user-product';

  export default {
    name: 'ColumnTabsNav',
    props: {
      language: String,
      productId: String,
      lineId: String,
      selectedList: Array
    },
    data() {
      return {
        // 必填項目：產品介紹、費用細節、預訂須知、責任條款、取消政策
        defaultColumn: ['introduction', 'cost', 'booking', 'responsibility', 'policy'],
        tabList: [],
        current: '',
        wrapWidth: 0,
        totalWidth: 0,
        translateX: 0
      };
    },
    components: {SelectColumnModal},
    created() {
      this.getProductLineColumn();
    },
    methods: {
      onPrevPage() {
        let translateX = this.translateX + this.wrapWidth;
        if (translateX > 0) {
          translateX = 0;
        }
        this.translateX = translateX;
      },
      onNextPage() {
        let translateX = this.translateX - this.wrapWidth;
        const maxX = -(this.totalWidth - this.wrapWidth);
        if (translateX < maxX) {
          translateX = maxX;
        }
        this.translateX = translateX;
      },
      // 获取产品栏目与路线关联列表（不分页）
      getProductLineColumn() {
        if (!this.lineId) {
          this.tabList = [];
          return false;
        }
        getProductLineColumn(this.language, this.lineId).then(res => {
          const list = res.value || [];
          this.tabList = list;
          this.setCurrent((list[0] || {}).programLabel || '');
          this.translateX = 0;
          this.$emit('update:selectedList', list);
          this.$nextTick(() => {
            this.wrapWidth = parseInt(this.$refs.tabListWrap.offsetWidth);
            this.totalWidth = parseInt(this.$refs.tabList.offsetWidth);
            this.$nextTick(() => {
              this.wrapWidth = parseInt(this.$refs.tabListWrap.offsetWidth);
            });
          });
          this.setDefaultColumn(list);
        });
      },
      setCurrent(value) {
        this.current = value;
        this.$emit('input', value);
      },
      // 选中默认栏目(必填項目：產品介紹、費用細節、預訂須知、責任條款、取消政策)
      setDefaultColumn(list) {
        const programLabels = list.map(item => item.programLabel);
        const isSetDefaultColumn = programLabels.filter(item => this.defaultColumn.includes(item)).length !== this.defaultColumn.length;
        if (isSetDefaultColumn) {
          let newColumns = [...list];
          getProductColumnList(this.language).then(res => {
            if (res.code === 200) {
              const allColumn = res.value;
              const notColumn = this.defaultColumn.filter(item => !programLabels.includes(item));
              const selecteds = allColumn.filter(item => notColumn.includes(item.programLabel));
              newColumns = newColumns.concat(selecteds);

              const data = newColumns.map(item => {
                return {productId: this.productId, lineId: this.lineId, programId: item.programId};
              });
              saveProductLineColumn(data).then(() => {
                this.getProductLineColumn();
              });
            }
          });
        }
      }
    },
    watch: {
      lineId: function (value) {
        this.getProductLineColumn();
      }
    }
  };
</script>
<style scoped lang="less">
  .column-tabs-nav{
    margin-bottom:20px;padding:30px 0 24px;overflow:hidden;;border-bottom:1px dashed #ccc;
    .wrap{
      display:flex;align-items:center;position:relative;
    }
    .inner{flex:1;position:relative;height:22px;overflow:hidden;}
  }
  .tab-list{float:left;padding:0 8px;white-space:nowrap;font-size:0;transition:transform 0.3s;}
  .tab-item{
    display:inline-block;margin-right:24px;line-height:22px;cursor:pointer;font-size:16px;
    &:last-child{margin-right:0;}
    &.active{color:#ff6f61;}
  }
  .btn-prev,
  .btn-next{
    display:block;width:16px;height:22px;cursor:pointer;background:#fff url(../../../assets/images/btn-prev-1.png);
    &:hover{opacity:0.8;}
  }
  .btn-next{background-image:url(../../../assets/images/btn-next-1.png);}
  .btn-setting{float:right;margin-left:16px;line-height:22px;color:#ff6f61;font-size:16px;}
  .i-setting{width:16px;height:16px;margin-right:4px;vertical-align:middle;background-image:url(../../../assets/images/btn-setting.png);}
</style>
