<template>
  <div class="basic-info">
    <div class="h2">{{$t('BasicInformation')}}</div>
    <table class="form-table">
<!--      <tr>-->
<!--        <td>{{$t("FlightInformation")}}：</td>-->
<!--        <td><checkbox v-model="isFlight"></checkbox></td>-->
<!--      </tr>-->
      <tr>
      <tr>
        <td>是否一日：</td>
        <td><checkbox v-model="isDay"></checkbox></td>
      </tr>
      <tr v-if="isDay==false||isDay===''">
        <td class="label" :class="{'is-error': $v.days.$error||$v.lates.$error}">{{$t('Days')}}：</td>
        <td>
          <input class="ipt w1 m-r vam" type="number" autocomplete="off"
                 :class="{'is-error': $v.days.$error}"
                 @blur="$v.days.$touch()"
                 v-model.number="days"/>
          <span class="vam m-r">{{$t('Day')}}</span>
          <input class="ipt w1 m-r vam" type="number" autocomplete="off"
                 :class="{'is-error': $v.lates.$error}"
                 @blur="$v.lates.$touch()"
                 v-model.number="lates"/>
          <span class="vam">{{$t('Night')}}</span>
        </td>
      </tr>
      <tr>
        <td class="label" :class="{'is-error': $v.title.$error}">{{$t('ProductName')}}：</td>
        <td>
          <el-input class="word-limit"
                    v-model="title"
                    size="small"
                    maxlength="100"
                    show-word-limit
                    :class="{'is-error': $v.title.$error}"
                    @blur="$v.title.$touch()"/>
        </td>
      </tr>
      <tr>
        <td class="label" :class="{'is-error': $v.introduction.$error}">{{$t('ProductIntroduction')}}：</td>
        <td>
          <el-input type="textarea"
                    v-model="introduction"
                    maxlength="2000"
                    show-word-limit
                    :class="{'is-error': $v.introduction.$error}"
                    @blur="$v.introduction.$touch()"/>
        </td>
      </tr>
      <tr>
        <td class="label" :class="{'is-error': $v.areas.$error}">{{$t('TourGroupCountry')}}：</td>
        <td>
          <el-select class="select" filterable multiple size="small"
                     :placeholder="$t('Can be entered in continents, countries or regions')"
                     v-model="areas"
                     :class="{'is-error': $v.areas.$error}"
                     @remove-tag="()=>$v.areas.$touch()"
                     @visible-change="(val)=>!val&&$v.areas.$touch()">
            <el-option v-for="item of countryOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </td>
      </tr>
      <!--參團地點-->
      <tr>
        <td class="label">{{$t('Participation place')}}：</td>
        <td>
          <radio v-model="participationLocation" label="departure">{{$t('Departure place')}}</radio>
          <radio v-model="participationLocation" label="destination">{{$t('Destination group')}}</radio>
        </td>
      </tr>
      <!--出發地-->
      <tr>
        <td class="label" :class="{'is-error': $v.departureCity.$error}">{{$t('Departure')}}：</td>
        <td>
          <!--          <el-select class="select" filterable remote :remote-method="(query)=>filterDepartureCity=query" size="small"-->
          <el-select class="select" filterable size="small"
                     :placeholder="$t('Can enter country or region, state or province, city, district or county')"
                     v-model="departureCity"
                     :class="{'is-error': $v.departureCity.$error}"
                     @visible-change="(val)=>!val&&$v.departureCity.$touch()">
            <template v-for="item of allCityOptions">
              <el-option :key="item.id"
                         :label="item.name"
                         :value="item.code"
              >
                <!--                         v-if="item.code===departureCity||(filterDepartureCity&&item.name.includes(filterDepartureCity))">-->
              </el-option>
            </template>
          </el-select>
        </td>
      </tr>
      <tr>
        <td class="label" :class="{'is-error': $v.destinationCity.$error}">{{$t('Destination')}}：</td>
        <td>
          <el-select class="select" filterable multiple
                     size="small"
                     :placeholder="$t('Can enter country or region, state or province, city, district or county')"
                     v-model="destinationCity"
                     :class="{'is-error': $v.destinationCity.$error}"
                     @remove-tag="()=>$v.destinationCity.$touch()"
                     @visible-change="(val)=>!val&&$v.destinationCity.$touch()">
            <template v-for="item of allCityOptions">
              <el-option :key="item.id"
                         :label="item.name"
                         :value="item.code"
              >
              </el-option>
            </template>
          </el-select>
        </td>
      </tr>
      <tr>
        <td class="label" :class="{'is-error': $v.transportation.$error}">{{$t('Transportation')}}：</td>
        <td>
          <el-select class="select m-r" size="small" multiple
                     v-model="transportation"
                     :class="{'is-error': $v.transportation.$error}"
                     @remove-tag="()=>$v.transportation.$touch()"
                     @visible-change="(val)=>!val&&$v.transportation.$touch()">
            <el-option v-for="item in transportationOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </td>
      </tr>
      <tr>
        <td class="label" :class="{'is-error': $v.classifications.$error}">{{$t('ProductCategory')}}：</td>
        <td>
          <checkbox v-for="item of productCategory"
                    :value="classifications.includes(item.code)"
                    :key="item.id"
                    @click.native="selectClassifications(item.code)">
            <div class="checkbox-label">{{item.name}}</div>
          </checkbox>
        </td>
      </tr>
      <!--產品性質-->
      <tr>
        <td class="label">{{$t('ProductNature')}}：</td>
        <td>
          <radio v-model="productNature" label="tourGroup">{{$t('TourGroup')}}</radio>
          <radio v-model="productNature" label="freeTravel">{{$t('FreeTravel')}}</radio>
        </td>
      </tr>
      <!--結算貨幣-->
      <tr>
        <td class="label">{{$t('BillingCurrency')}}：</td>
        <td>
          <input class="ipt" type="text" disabled="disabled" autocomplete="off"
                 :value="$t('Currencys')[paymentCurrency]"/>
        </td>
      </tr>
      <!--封面圖片-->
      <tr>
        <td class="label" :class="{'is-error': $v.photoUrl.$error}">{{$t('Cover')}}：</td>
        <td>
          <upload-cover v-model="photoUrl" http-type="v2"/>
        </td>
      </tr>
      <!--可否取消預定-->
      <!--      <tr>-->
      <!--        <td class="label">{{$t('isCancelReservation')}}：</td>-->
      <!--        <td>-->
      <!--          <radio v-model="isCancelOrder" label="Y">{{$t('Can')}}</radio>-->
      <!--          <radio v-model="isCancelOrder" label="N">{{$t('Cannot')}}</radio>-->
      <!--        </td>-->
      <!--      </tr>-->
    </table>
    <!--<div class="cl">
      <span class="vam">{{$t('isLivingTogether')}}：</span>
      <radio v-model="isBuildingRoom" label="Y">{{$t('Can')}}</radio>
      <radio v-model="isBuildingRoom" label="N">{{$t('Cannot')}}</radio>
    </div>-->
    <div class="actions">
      <button class="button button-primary" type="button" @click="onSubmit">{{$t('Save')}}</button>
      <button class="button" type="button" @click="nextPage">{{$t('NextStep')}}</button>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';
  import {required, minLength} from 'vuelidate/lib/validators';
  import Checkbox from '../../../components/Checkbox';
  import Radio from '../../../components/Radio';
  import UploadCover from '../../../components/UploadCover';
  import {scrollToY} from '../../../utils';
  import {getDictList, getAllCityList} from '../../../api/admin-common';
  import {saveProductInfo, getProductInfo} from '../../../api/user-product';

  export default {
    name: 'BasicInformation',
    props: {
      productId: String,
      competitionID: String,
      language: String,
      code: String,
      name: String
    },
    data() {
      return {
        isFlight: true,
        isOldFlight: true, // 判断isFlight有无更改
        isDay: false,
        isOldDay: false,
        days: '',
        lates: '',
        title: '',
        introduction: '',
        allCityOptions: [],
        departureCity: '',
        destinationCity: [],
        transportationOptions: [],
        transportation: [],
        productCategory: [],
        classifications: [],
        productNature: 'tourGroup',
        photoUrl: '',
        isCancelOrder: 'Y',
        areas: [],
        countryOptions: [],
        participationLocation: 'departure',
        // 用于过滤城市字典
        filterDepartureCity: '',
        filterDestinationCity: ''
      };
    },
    validations: {
      days: {required},
      lates: {required},
      title: {required},
      introduction: {required},
      areas: {required, minLength: minLength(1)},
      departureCity: {required},
      destinationCity: {required, minLength: minLength(1)},
      transportation: {required, minLength: minLength(1)},
      classifications: {required, minLength: minLength(1)},
      photoUrl: {required}
    },
    computed: {...mapGetters(['paymentCurrency'])},
    components: {UploadCover, Checkbox, Radio},
    mounted() {
      this.getDicts();
      this.getDetail();
    },
    destroyed() {
      // if (this.isDay !== this.isOldDay || this.isFlight !== this.isOldFlight) {
      //   this.$msgbox({
      //     title: this.$t('Prompt'),
      //     message: this.$t('confirm.UnsavedPrompt'),
      //     confirmButtonText: this.$t('Save'),
      //     center: true,
      //     showClose: false,
      //     showCancelButton: true,
      //     lockScroll: false,
      //     beforeClose: (action, instance, done) => {
      //       if (action === 'confirm') {
      //         instance.confirmButtonLoading = true;
      //         this.onSubmit(() => {
      //           instance.confirmButtonLoading = false;
      //           done();
      //         }, true);
      //       } else {
      //         done();
      //       }
      //     }
      //   }).then(action => {
      //   }).catch(() => {
      //   });
      // }
    },
    methods: {
      // 获取字典
      getDicts() {
        getDictList('country').then(res => {
          this.countryOptions = res.value;
        });
        getDictList('transportation').then(res => {
          this.transportationOptions = res.value || [];
        });
        getDictList('productClassification').then(res => {
          this.productCategory = res.value || [];
        });
        // 获取所有城市
        getAllCityList().then(res => {
          this.allCityOptions = res.value;
        });
      },

      // 获取产品详情
      getDetail() {
        if (this.productId) {
          const loading = this.$loading({text: 'Loading'});
          getProductInfo(this.language, this.productId).then(res => {
            const value = res.value;
            console.log(value);
            if (value) {
              this.days = value.days;
              this.lates = value.lates;
              this.transportation = value.transportation ? value.transportation.split(';') : [];
              this.productNature = value.productNature;
              this.photoUrl = value.photoUrl;
              this.isCancelOrder = value.isCancelOrder;
              this.classifications = (value.classifications || '').split(',');
              this.title = value.title;
              this.areas = value.areas ? value.areas.split(';') : [];
              this.departureCity = value.departureCity;
              this.destinationCity = value.destinationCity ? value.destinationCity.split(';').filter(item => !!item) : [];
              this.introduction = value.introduction;
              this.participationLocation = value.participationLocation;
              this.isFlight = value.isFlight;
              this.isOldFlight = Boolean(value.isFlight);
              this.isDay = Boolean(value.isDay);
              this.isOldDay = Boolean(value.isDay);
              this.$emit('update:code', value.productCode);
              this.$emit('update:name', value.title);
            }
            loading.close();
          }).catch(() => {
            loading.close();
          });
        }
      },
      // 选中/取消选中产品分类
      selectClassifications(classificationsId) {
        if (this.classifications.includes(classificationsId)) {
          this.classifications.splice(this.classifications.indexOf(classificationsId), 1);
        } else {
          this.classifications.push(classificationsId);
        }
      },
      onSubmit(callback) {
        if (this.isDay === true) {
          this.days = 1;
          this.lates = 0;
        }
        let data = {
          days: this.days,
          lates: this.lates,
          transportation: this.transportation.join(';'),
          productNature: this.productNature,
          photoUrl: this.photoUrl,
          isCancelOrder: this.isCancelOrder,
          maxOrderPeople: 0,
          minOrderPeople: 0,
          classifications: this.classifications.join(),
          title: this.title,
          areas: this.areas.join(';'),
          departureCity: this.departureCity,
          destinationCity: this.destinationCity.join(';'),
          introduction: this.introduction,
          languageType: this.language,
          participationLocation: this.participationLocation,
          isFlight: Number(this.isFlight),
          isDay: Number(this.isDay),
          isCompetition: 1,
          competitionID: this.competitionID
        };
        console.log(data);

        this.$v.$touch();

        if (Object.values(data).filter(item => item === '').length) {
          this.$message.warning(this.$t('Please complete the information before submitting'));
          this.$nextTick(() => {
            if (document.querySelector('.is-error')) {
              scrollToY(undefined, document.querySelector('.is-error').offsetTop);
            }
          });
          return false;
        }
        data.productId = this.productId;
        const loading = this.$loading({text: 'Loading'});
        saveProductInfo(data).then(res => {
          this.$emit('update:productId', res.value.productId);
          if (typeof callback === 'function') {
            callback();
          }
          loading.close();
          this.$message.success(this.$t('SaveSuccessfully'));
          window.sessionStorage.setItem('productId', res.value.productId);
        }).catch(() => {
          loading.close();
        });
      },
      // 下一步
      nextPage() {
        this.onSubmit(() => {
          // this.$emit('update:code', this.productCode);
          this.$emit('update:name', this.title);
          this.$emit('next-step', 'Multimedia');
        });
      }
    },
    watch: {
      isFlight: function (val) {
        if (!this.productId) {
          this.isOldFlight = val;
        }
        this.$emit('getChild', val);
      },
      isDay: function (val) {
        if (!this.productId) {
          this.isOldDay = val;
        }
        this.$emit('getChildIsDay', val);
      }
    }
  };
</script>
<style scoped lang="less">
  .basic-info{
    font-size:16px;
    .h2{
      position:relative;margin:30px 0;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .ipt{
      width:400px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
      &.full{width:100%;}
      &.w1{width:80px;}
    }
    .select{
      width:400px;vertical-align:top;
      &.w1{width:250px;}
      &.w2{width:100px;}
    }
    /deep/ .el-autocomplete{
      width:250px;
      .el-input{vertical-align:top;}
    }
    /deep/ .checkbox-wrap{
      display:inline-block;margin-right:16px;line-height:1;vertical-align:middle;
      .checkbox-label{margin-left:20px;}
    }
    /deep/ .radio{
      .label{color:#321908;}
    }
    .m-r{margin-right:8px;}
  }
  .form-table{
    width:100%;
    td{padding-bottom:24px;}
    .label{width:112px;line-height:32px;white-space:nowrap;}
  }
  .people-num-wrap{
    margin-bottom:30px;
    .tit,
    .lab{margin-right:8px;line-height:32px;}
    .item{margin-right:32px;}
  }
  .area-item{
    position:relative;float:left;margin:10px 10px 0 0;padding:0 24px;line-height:32px;border:1px solid #d9d9d9;border-radius:4px;
    &:hover{
      .btn-del{display:block;}
    }
    .btn-del{display:none;position:absolute;top:-4px;right:-4px;width:20px;height:20px;background:url(../../../assets/images/icon/close-circle.png) no-repeat 0 0;}
  }
  .word-limit{
    /deep/ .el-input__inner{padding-right:60px;}
  }
</style>
