import request from '@/utils/request';
import {getLocale} from '../utils/language';

const language = getLocale() || 'zh-hk';

// 获取产品列表
export function getProductList(data) {
  return request({
    url: `/competitionProduct/list/${language}`,
    method: 'get',
    params: data
  });
}

// 修改产品状态
export function updateProductStatus(productId, status) {
  return request({
    url: `/competitionProduct/update/status/${productId}/${status}`,
    method: 'post'
  });
}

// 删除产品
export function deleteProduct(productId) {
  return request({
    url: `/competitionProduct/delete/${productId}`,
    method: 'post'
  });
}

// 保存产品基本信息
export function saveProductInfo(data) {
  return request({
    url: '/competitionProduct/save',
    method: 'post',
    data
  });
}

// 获取产品基本信息
export function getProductInfo(language, productId) {
  return request({
    url: `/competitionProduct/details/${language}/${productId}`,
    method: 'get'
  });
}

// 保存产品路线
export function saveProductLine(data) {
  return request({
    url: '/competitionProduct/line/save',
    method: 'post',
    data
  });
}

// 删除产品路线
export function deleteProductLine(lineId) {
  return request({
    url: `/competitionProduct/line/delete/${lineId}`,
    method: 'post'
  });
}

// 获取产品路线
export function getProductLine(language, productId) {
  return request({
    url: `/competitionProduct/line/list/${language}/${productId}`,
    method: 'get'
  });
}

// 保存产品路线行程
export function saveProductSchedule(data) {
  return request({
    url: '/competitionProduct/line/travel/save',
    method: 'post',
    data
  });
}

// 删除产品路线行程
export function deleteProductSchedule(data) {
  return request({
    url: '/competitionProduct/line/travel/delete',
    method: 'post',
    data
  });
}

// 获取产品路线行程列表
export function getProductSchedule(language, lineId) {
  return request({
    url: `/competitionProduct/line/travel/list/${language}/${lineId}`,
    method: 'get'
  });
}

// 保存产品多媒体
export function saveProductMediaList(data, type) {
  return request({
    url: `/competitionProduct/media/save/${type}`,
    method: 'post',
    data
  });
}

// 获取产品多媒体列表
export function getProductMediaList(language, productId) {
  return request({
    url: `/competitionProduct/media/list/${language}/${productId}`,
    method: 'get'
  });
}

// 删除产品多媒体
export function deleteProductMedia(data) {
  return request({
    url: '/competitionProduct/media/delete',
    method: 'post',
    data
  });
}

// 设置产品多媒体默认大图
export function setProductMediaDefaultImg(mediaId) {
  return request({
    url: `/competitionProduct/save/media/photo/isDefault/${mediaId}`,
    method: 'post'
  });
}

// 保存产品路线费用
export function saveProductCost(data) {
  return request({
    url: '/competitionProduct/line/cost/save',
    method: 'post',
    data
  });
}

// 获取产品路线费用
export function getProductCost(language, lineId) {
  return request({
    url: `/competitionProduct/line/cost/${language}/${lineId}`,
    method: 'get'
  });
}

// 保存产品路线 - 通用
export function saveProductCommon(data) {
  return request({
    url: '/competitionProduct/line/current/save',
    method: 'post',
    data
  });
}

// 获取产品路线通用详情
export function getProductCommonDetail(language, lineId, programLabel) {
  return request({
    url: `/competitionProduct/line/current/details/${language}/${lineId}/${programLabel}`,
    method: 'get'
  });
}

// 获取产品路线通用集合
export function getProductCommonList(language, lineId) {
  return request({
    url: `/competitionProduct/line/current/list/${language}/${lineId}`,
    method: 'get'
  });
}

// 添加产品路线行程附件
export function saveProductAttachment(data) {
  return request({
    url: '/competitionProduct/line/save/file',
    method: 'post',
    data
  });
}

// 获取产品路线行程附件
export function getProductAttachment(lineId) {
  return request({
    url: `/competitionProduct/line/file/${lineId}`,
    method: 'get'
  });
}

// 保存产品路线价格按星期
export function addProductPriceByWeek(data) {
  return request({
    url: '/competitionProduct/price/add/week',
    method: 'post',
    data
  });
}

// 保存产品路线价格按日期
export function addProductPriceByDate(data) {
  return request({
    url: '/competitionProduct/price/add/date',
    method: 'post',
    data
  });
}

// 修改产品路线价格按星期
export function updateProductPriceByWeek(data) {
  return request({
    url: '/competitionProduct/price/update/week',
    method: 'post',
    data
  });
}

// 批量关闭产品路线价格
export function closeProductPrice(lineId, data) {
  return request({
    url: `/competitionProduct/price/close/date/${lineId}`,
    method: 'post',
    data
  });
}

// 批量开启产品路线价格
export function openProductPrice(lineId, data) {
  return request({
    url: `/competitionProduct/price/open/date/${lineId}`,
    method: 'post',
    data
  });
}

// 获取产品路线价格集合
export function getProductPriceList(lineId) {
  return request({
    url: `/competitionProduct/price/list/${lineId}`,
    method: 'get'
  });
}

// 获取产品价格列表
export function getProductPriceListByproductId(productId) {
  return request({
    url: `/competitionProduct/price/list/${productId}/${language}`,
    method: 'get'
  });
}

// 获取产品路线价格按日期
export function getProductPriceByDate(lineId, priceDate) {
  return request({
    url: `/competitionProduct/price/date/${lineId}/${priceDate}`,
    method: 'get'
  });
}

// 修改产品路线价格按主键
export function updateProductPriceByDate(data) {
  return request({
    url: '/competitionProduct/price/update/by/id',
    method: 'post',
    data
  });
}

// 获取产品栏目列表（不分页）
export function getProductColumnList(language) {
  return request({
    url: `/competitionProduct/program/list/${language}`,
    method: 'get'
  });
}

// 保存产品栏目(自定义)
export function saveProductColumn(data) {
  return request({
    url: '/competitionProduct/program/save',
    method: 'post',
    data
  });
}

// 删除产品栏目（自定义）
export function deleteProductColumn(programId) {
  return request({
    url: `/competitionProduct/program/delete/${programId}`,
    method: 'post'
  });
}

// 栏目与产品路线关联
export function saveProductLineColumn(data) {
  return request({
    url: '/competitionProduct/line/add/program',
    method: 'post',
    data
  });
}

// 获取产品栏目与路线关联列表（不分页）
export function getProductLineColumn(language, lineId) {
  return request({
    url: `/competitionProduct/line/program/list/${language}/${lineId}`,
    method: 'get'
  });
}

// 保存产品路线产品介绍
export function saveProductLineIntroduction(data) {
  return request({
    url: '/competitionProduct/line/save/introduction',
    method: 'post',
    data
  });
}

// 获取产品路线产品介绍
export function getProductLineIntroduction(language, lineId) {
  return request({
    url: `/competitionProduct/line/introduction/${language}/${lineId}`,
    method: 'get'
  });
}

// 保存产品路线签证须知
export function saveProductLineVisa(data) {
  return request({
    url: '/competitionProduct/line/save/visa',
    method: 'post',
    data
  });
}

// 获取产品路线签证须知
export function getProductLineVisaList(language, lineId) {
  return request({
    url: `/competitionProduct/line/visa/list/${language}/${lineId}`,
    method: 'get'
  });
}

// 获取产品路线航班资料列表
export function getProductFlightLineList(data) {
  return request({
    url: '/competitionProduct/flight/list',
    method: 'post',
    data
  });
}

// 保存产品路线航班资料
export function saveProductFlight(data) {
  return request({
    url: '/competitionProduct/flight/save',
    method: 'post',
    data
  });
}

// 清除产品路线航班资料
export function clearProductFlight(lineId, departureDate) {
  return request({
    url: `/competitionProduct/flight/clear/${lineId}/${departureDate}`,
    method: 'post'
  });
}

// 获取产品路线航班资料详情
export function getProductFlightDetails(data) {
  return request({
    url: '/competitionProduct/flight/details',
    method: 'post',
    data
  });
}

// 复制产品路线
export function copyProductLine(lineId, lineName) {
  return request({
    url: `/competitionProduct/line/copy/${lineId}/${lineName}`,
    method: 'post'
  });
}

// 保存退费政策
export function saveRefundPolicy(data) {
  return request({
    url: '/competitionProduct/line/policy/save',
    method: 'post',
    data
  });
}

// 获取退费政策
export function getRefundPolicy(language, lineId) {
  return request({
    url: `/competitionProduct/line/policy/${language}/${lineId}`,
    method: 'get'
  });
}

// 模糊查询产品信息
// params:productCode
// params:productTitle
export function getProductInfoList(data) {
  return request({
    url: `/competitionProduct/search/${language}`,
    method: 'post',
    data
  });
}

// 获取产品基本信息
export function userGetProduct(productId) {
  return request({
    url: `/competitionProduct/getProduct/${productId}`,
    method: 'get'
  });
}
