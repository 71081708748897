<template>
  <div class="cost-details">
    <div class="title">{{$t('CostIncludes')}}：</div>
    <el-input type="textarea"
              :placeholder="$t('PleaseEnter')"
              v-model="includeContent"
              maxlength="1000"
              show-word-limit>
    </el-input>
    <div class="title m-t">{{$t('CostDoesNotInclude')}}：</div>
    <el-input type="textarea"
              :placeholder="$t('PleaseEnter')"
              v-model="notIncludeContent"
              maxlength="1000"
              show-word-limit>
    </el-input>
    <div class="actions">
      <button class="button button-primary" type="button" @click="onSubmit">{{$t('Save')}}</button>
      <button class="button" type="button" @click="onNext">{{$t('NextStep')}}</button>
    </div>
  </div>
</template>
<script>
  import {saveProductCost, getProductCost} from '../../../api/user-product';

  export default {
    name: 'CostDetails',
    props: {
      productId: String,
      language: String,
      lineId: String
    },
    data() {
      return {
        costId: '',
        include: false,
        includeContent: '',
        notinclude: false,
        notIncludeContent: '',
        oldIncludeContent: '',
        oldNotIncludeContent: ''
      };
    },
    created() {
      this.getProductCost();
    },
    destroyed() {
      if (this.includeContent !== this.oldIncludeContent || this.notIncludeContent !== this.oldNotIncludeContent) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.UnsavedPrompt'),
          confirmButtonText: this.$t('Save'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              this.onSubmit(() => {
                instance.confirmButtonLoading = false;
                done();
              }, true);
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      }
    },
    methods: {
      // 获取产品路线费用
      getProductCost() {
        getProductCost(this.language, this.lineId).then(res => {
          const value = res.value || {};
          this.costId = value.costId;
          this.includeContent = value.includeContent;
          this.notIncludeContent = value.notIncludeContent;
          this.oldIncludeContent = value.includeContent;
          this.oldNotIncludeContent = value.notIncludeContent;
        });
      },
      // 存储
      onSubmit(callback, isForcedSave) {
        const data = {
          productId: this.productId,
          lineId: this.lineId,
          languageType: this.language,
          costId: this.costId,
          includeContent: this.includeContent,
          notIncludeContent: this.notIncludeContent
        };

        saveProductCost(data).then(res => {
          this.oldIncludeContent = this.includeContent;
          this.oldNotIncludeContent = this.notIncludeContent;
          this.costId = res.value.costId;
          !isForcedSave && this.$message.success(this.$t('SaveSuccessfully'));
          if (typeof callback === 'function') {
            callback();
          }
        });
      },
      // 下一步
      onNext() {
        this.onSubmit(() => {
          this.$parent.nextStep();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .cost-details{
    .title{font-size:16px;}
  }
</style>
