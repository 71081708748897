<template>
  <div class="tabs-nav cl">
    <div class="item" v-for="(item,index) of line"
         :class="{active:index===lineIndex||item.isEdit}"
         :key="item.id"
         @click="changeLine(index)"
         @dblclick="item.isEdit=true">
      <input type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
             v-focus
             v-model.trim="item.lineTitle"
             @keyup.enter="onSave(index,item)"
             @blur="onSave(index,item)"
             v-if="item.isEdit"/>
      <template v-else>
        <div class="letter">{{item.lineName}}</div>
        <div class="line-title">{{item.lineTitle}}</div>
        <div class="btns cl">
          <a class="btn-copy" @click.stop="copyLine(item.lineId)"></a>
          <a class="btn-edit" @click.stop="item.isEdit=true"></a>
          <a class="btn-del" @click.stop="deleteLine(index)"></a>
        </div>
      </template>
    </div>
    <a class="btn-add-route" href="javascript:;" @click="addLineItem">
      <img class="vam" src="../../../assets/images/icon/plus-circle-red.png"/>
      <span class="vam"> {{$t('AddRoute')}}</span>
    </a>
  </div>
</template>
<script>
  import {getNextLetter} from '../../../utils';
  import {getProductLine, deleteProductLine, saveProductLine, copyProductLine} from '../../../api/user-product';

  export default {
    name: 'RouteTabsNav',
    directives: {
      focus: {
        inserted: function (el) {
          el.focus();
        }
      }
    },
    props: {
      language: String,
      productId: String,
      list: Array
    },
    data() {
      return {
        line: [],
        lineIndex: 0
      };
    },
    created() {
      this.getProductLine();
    },
    methods: {
      // 获取产品路线
      getProductLine() {
        getProductLine(this.language, this.productId).then(res => {
          this.line = res.value.map(item => {
            return {
              productId: item.productId,
              languageType: item.languageType,
              lineId: item.lineId,
              lineName: item.lineName,
              lineTitle: item.lineTitle,
              isEdit: false
            };
          });
          this.changeLine(this.lineIndex);
          this.$emit('update:list', this.line);
        });
      },
      // 删除路线
      deleteLine(index) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.delete'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              this.onDeleteLine(index, instance, done);
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      },
      onDeleteLine(index, instance, done) {
        const lineId = this.line[index].lineId;
        deleteProductLine(lineId).then(res => {
          this.line.splice(index, 1);
          if (this.lineIndex > this.line.length - 1) {
            this.changeLine(0);
          }
          this.$emit('update:list', this.line);
          instance.confirmButtonLoading = false;
          done();
        }).catch(() => {
          instance.confirmButtonLoading = false;
        });
      },
      // 切换路线
      changeLine(index) {
        let lineId = '';
        if (this.line.length) {
          lineId = this.line[index].lineId;
        }
        this.lineIndex = index;
        this.$emit('input', lineId);
      },
      // 添加路线
      addLineItem() {
        const data = {
          productId: this.productId,
          languageType: this.language,
          lineId: '',
          lineName: this.line.length ? getNextLetter(this.line[this.line.length - 1].lineName) : 'A',
          lineTitle: '',
          isEdit: true
        };
        this.line.push(data);
      },
      // 保存路线
      onSave(index, item) {
        if (!this.line[index]) {
          return false;
        }
        // 防止重复提交
        if (!this.line[index].isEdit) {
          return false;
        }
        this.line[index].isEdit = false;
        if (!item.lineId && !item.lineTitle) {
          this.line.splice(index, 1);
          return false;
        }

        const data = {
          productId: this.productId,
          languageType: this.language,
          lineId: item.lineId,
          lineName: item.lineName,
          lineTitle: item.lineTitle
        };
        saveProductLine(data).then(res => {
          // this.$message.success(this.$t('SaveSuccessfully'));
          this.getProductLine();
        });
      },
      // 复制路线
      copyLine(lineId) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.CopyLine'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              copyProductLine(lineId, getNextLetter(this.line[this.line.length - 1].lineName)).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.$message.success(this.$t('confirm.CopySuccess'));
                this.getProductLine();
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .tabs-nav{
    margin-top:22px;user-select:none;
    .item{
      position:relative;float:left;width:132px;height:44px;margin-top:8px;margin-right:8px;padding:4px 10px;line-height:17px;text-align:center;color:#999;border:1px dashed #ccc;border-radius:4px;cursor:pointer;
      &.active{color:#ff6f61;border-color:#ff6f61;}
      &:hover{
        border-color:#ff6f61;
        .btns{display:block;}
      }
      input{width:100%;height:100%;text-align:center;border:none;background-color:transparent;}
    }
    .letter{font-size:18px;}
    .line-title{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-size:12px;}
    .btn-add-route{
      float:left;margin-top:8px;margin-left:8px;line-height:44px;color:#ff6f61;font-size:18px;
    }
    .btns{
      display:none;position:absolute;top:0;right:0;
      > a{
        float:left;width:16px;height:16px;opacity:0.8;cursor:pointer;
        &:hover{opacity:1;}
      }
    }
    .btn-del{background:url(../../../assets/images/icon/ashcan-red.png) no-repeat 0 0;}
    .btn-edit{background:url(../../../assets/images/icon/pen-red.png) no-repeat 0 0;}
    .btn-copy{margin-right:2px;background:url(../../../assets/images/icon/copy.png) no-repeat 0 0;}
  }
</style>
