<template>
  <div>
    <div class="self-funded">
      <div class="m-t" :class="{'is-error': $v.content.$error}">
<!--        <froala :config="froalaConfig" v-model="content"/>-->
        <editor-elem v-model="content"/>
      </div>
    </div>
    <div class="refund-fee">
      <div class="h3"><span class="required">*</span> 退款安排</div>
      <table class="compensation">
        <thead>
        <tr>
          <th colspan="2"><span class="required">* </span>{{$t('BreachTime')}}</th>
          <th class="cl" width="200">
            <div>
              <div class="fl">
                <span class="required">* </span>
              </div>
              <div class="fl">
                <div>{{$t('PassengerBreach')}}</div>
                <div class="proportion-txt">{{$t('PassengerFeePercentage')}}</div>
              </div>
            </div>
          </th>
          <th width="200">
            <div class="cl">
              <div class="fl">
                <checkbox class="checkbox1" v-model="useTravelAgencyBreach"></checkbox>
              </div>
              <div class="fl">
                <div>{{$t('TravelAgencyBreach')}}</div>
                <div class="proportion-txt">{{$t('TravelAgencyFeePercentage')}}</div>
              </div>
            </div>
          </th>
          <th width="80">{{$t('Operating')}}</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(item,index) of $v.formData.$each.$iter">
          <tr :key="'btn'+index" v-if="item.position.$model==='end'">
            <td colspan="2"></td>
            <td>
              <div style="padding-left:20px">
                <a class="btn-add" @click="addItem()">
                  <img class="vam" src="../../../assets/images/icon/plus-circle-red.png"/>
                  <span class="vam"> {{$t('New')}}</span>
                </a>
              </div>
            </td>
            <td colspan="2"></td>
          </tr>
          <tr :key="index">
            <td width="310">
              <div class="txt-r" v-if="['start','middle'].includes(item.position.$model)">
                <el-input-number class="input-number" :min="0" :max="999" size="small" :controls="false"
                                 step-strictly
                                 :class="{'is-error': item.minDay.$error}"
                                 @blur="item.minDay.$touch()"
                                 v-model="item.minDay.$model"/>
                <template v-if="item.position.$model==='middle'">
                  <span class="vam m-l">{{$t('DayTo')}}</span>
                  <el-input-number class="input-number m-l" :min="0" :max="999" size="small" :controls="false"
                                   step-strictly
                                   :class="{'is-error': item.maxDay.$error}"
                                   @blur="item.maxDay.$touch()"
                                   v-model="item.maxDay.$model"/>
                </template>
              </div>
            </td>
            <td style="padding-left:0">
              <!--n天或以上取消-->
              <span v-if="item.position.$model==='start'">{{$t('MoreThanAFewDays')}}</span>
              <span v-else-if="item.position.$model==='middle'">{{$t('DaysAgo')}}</span>
              <span v-else>{{$t('TheDayOfTheTrip')}}</span>
            </td>
            <td>
              <el-input-number class="input-number" :min="0" :max="100" size="small" :controls="false" step-strictly
                               :class="{'is-error': item.travelScale.$error}"
                               @blur="item.travelScale.$touch()"
                               v-model="item.travelScale.$model"/>
              <span class="vam m-l">%</span>
            </td>
            <td>
              <el-input-number class="input-number" :min="0" :max="100" size="small" :controls="false" step-strictly
                               :disabled="!useTravelAgencyBreach"
                               :class="{'is-error': item.adminScale.$error}"
                               @blur="item.adminScale.$touch()"
                               v-model="item.adminScale.$model"/>
              <span class="vam m-l">%</span>
            </td>
            <td>
              <a @click="deleteItem(index)" v-if="item.position.$model==='middle'">
                <img class="vam" src="../../../assets/images/icon/ashcan.png"/>
              </a>
            </td>
          </tr>
        </template>
        </tbody>
        <tfoot>
        <tr>
          <td colspan="5">
            <div class="prompt-content" v-html="$t('promptContent')"></div>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
    <div class="actions">
      <button class="button button-primary" type="button" :disabled="!content" @click="onSubmit">{{$t('Save')}}</button>
      <button class="button" type="button" @click="onNext">{{$t('NextStep')}}</button>
    </div>
  </div>
</template>
<script>
  import {required, requiredIf, between} from 'vuelidate/lib/validators';
  import Checkbox from '../../../components/Checkbox';
  import editor from '../../../mixins/editor';
  import {scrollToY, sortPolicy} from '../../../utils';
  import {getRefundPolicy, saveRefundPolicy} from '../../../api/user-product';
  import editorElem from '../../../components/EditorBar';
  export default {
    name: 'CancellationPolicy',
    i18n: {
      messages: {
        'zh-cn': {
          promptContent: '<p class="b">提示：</p><p>1．行程前天数：请由小到大写连续天数。例如16日至30日，8日至15日，1日至7日。</p><p>2．旅客违约与旅行社违约条款行程前天数必须一致，支付收取费用百分比可以不一致。</p><p>3．费用损失数值，请填写正整数，行程开始当日损失值为必填，支付总费用不可超过100%。</p><p>4．建议旅客违约收取费用比例与旅行社违约收取费用比例的差值不要大于30%。</p><p>5．未录入的天数，系统将按照0损失进行处理。例如：录入了行程的当天至行程前20天的退订规则，当旅游者提前21天及以上退订时，系统将按照0损失处理。</p><p>6．旅客违约与旅行社违约，以客人下单时的违约条款设置为准。</p><p>7．节假日产品，如果违约条款有所不同，请另开路线处理。</p>'
        },
        'zh-hk': {
          promptContent: '<p class="b">提示：</p><p>1．行程前天數：請由小到大寫連續天數。例如16日至30日，8日至15日，1日至7日。</p><p>2．旅客違約與旅行社違約條款行程前天數必須一致，支付收取費用百分比可以不一致。</p><p>3．費用損失數值，請填寫正整數，行程開始當日損失值為必填，支付總費用不可超過100%。</p><p>4．建議旅客違約收取費用比例與旅行社違約收取費用比例的差值不要大於30%。</p><p>5．未錄入的天數，系統將按照0損失進行處理。例如：錄入了行程的當天至行程前20天的退訂規則，當旅遊者提前21天及以上退訂時，系統將按照0損失處理。</p><p>6．旅客違約與旅行社違約，以客人下單時的違約條款設置為準。</p><p>7．節假日產品，如果違約條款有所不同，請另開路線處理。</p>'
        },
        'en': {
          promptContent: '<p class="b">提示：</p><p>1．行程前天數：請由小到大寫連續天數。例如16日至30日，8日至15日，1日至7日。</p><p>2．旅客違約與旅行社違約條款行程前天數必須一致，支付收取費用百分比可以不一致。</p><p>3．費用損失數值，請填寫正整數，行程開始當日損失值為必填，支付總費用不可超過100%。</p><p>4．建議旅客違約收取費用比例與旅行社違約收取費用比例的差值不要大於30%。</p><p>5．未錄入的天數，系統將按照0損失進行處理。例如：錄入了行程的當天至行程前20天的退訂規則，當旅遊者提前21天及以上退訂時，系統將按照0損失處理。</p><p>6．旅客違約與旅行社違約，以客人下單時的違約條款設置為準。</p><p>7．節假日產品，如果違約條款有所不同，請另開路線處理。</p>'
        }
      }
    },
    props: {
      productId: String,
      language: String,
      lineId: String,
      programLabel: String,
      programName: String
    },
    data() {
      return {
        currentId: '',
        content: '',
        formData: [],
        oldContent: '',
        oldFormData: '',
        useTravelAgencyBreach: false
      };
    },
    validations: {
      content: {required},
      formData: {
        required,
        $each: {
          maxDay: {
            required: requiredIf(function (datas) {
              return datas.position === 'middle';
            }),
            between: between(0, 9999)
          },
          minDay: {
            required: requiredIf(function (datas) {
              return ['start', 'middle'].includes(datas.position);
            }),
            between: between(0, 9999)
          },
          travelScale: {required, between: between(0, 100)},
          adminScale: {
            required: requiredIf(function () {
              return this.useTravelAgencyBreach;
            }),
            between: between(0, 100)
          },
          position: {required}
        }
      }
    },
    components: {Checkbox, editorElem},
    mixins: [editor],
    created() {
      this.getDetail();
    },
    destroyed() {
      if (this.content !== this.oldContent || JSON.stringify(this.formData) !== this.oldFormData) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.UnsavedPrompt'),
          confirmButtonText: this.$t('Save'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              this.onSubmit(() => {
                instance.confirmButtonLoading = false;
                done();
              }, true);
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      }
    },
    methods: {
      // 获取详情
      getDetail() {
        getRefundPolicy(this.language, this.lineId).then(res => {
          const value = res.value.current;
          const policies = res.value.policies || [];
          if (value) {
            this.currentId = value.currentId;
            this.content = value.content;
          }
          this.formData = sortPolicy(policies.filter(item => ['start', 'middle', 'end'].includes(item.position))).map(item => {
            item.adminScale = item.isAdmin === 'Y' ? item.adminScale : undefined;
            if (item.isAdmin === 'Y') {
              this.useTravelAgencyBreach = true;
            }
            return item;
          });
          if (!this.formData.length) {
            this.addItem('start');
            this.addItem('end');
          }
          this.setOldDatas();
        });
      },
      addItem(position = 'middle') {
        const item = {maxDay: undefined, minDay: undefined, travelScale: undefined, adminScale: undefined, position};
        if (position === 'middle') {
          this.formData.splice(this.formData.length - 1, 0, item);
        } else {
          this.formData.push(item);
        }
        this.$nextTick(() => {
          this.$v.$reset();
        });
      },
      deleteItem(index) {
        this.formData.splice(index, 1);
      },
      onSubmit(callback, isForcedSave) {
        let data = {
          productId: this.productId,
          lineId: this.lineId,
          languageType: this.language,
          currentId: this.currentId,
          programLabel: this.programLabel,
          programName: this.programName,
          isCustomize: 'N',
          content: this.content
        };
        const policies = this.formData.map(item => {
          let newItem = {...item};
          newItem.maxDay = item.maxDay !== undefined ? item.maxDay : '';
          newItem.minDay = item.minDay !== undefined ? item.minDay : '';
          newItem.adminScale = this.useTravelAgencyBreach ? item.adminScale : '';
          newItem.isDefault = ['start', 'end'].includes(item.position) ? 'Y' : 'N';
          newItem.isAdmin = this.useTravelAgencyBreach ? 'Y' : 'N';
          return newItem;
        });

        this.$v.$touch();
        if (this.$v.$invalid) {
          if (!isForcedSave) {
            this.$message.warning(this.$t('Please complete the information before submitting'));
            this.$nextTick(() => {
              if (document.querySelector('.is-error')) {
                scrollToY(undefined, document.querySelector('.is-error').offsetTop - 80);
              }
            });
          }
          return false;
        }
        // 判斷填寫的取消策略是否正確，是否由小至大，及是否連續天數。如是，才可以成功儲存。
        if (this.checkRefundPolicy(policies)) {
          saveRefundPolicy({current: data, policies}).then(res => {
            this.setOldDatas();
            if (!this.currentId) {
              this.currentId = res.value.currentId || '';
            }
            !isForcedSave && this.$message.success(this.$t('SaveSuccessfully'));
            if (typeof callback === 'function') {
              callback();
            }
          });
        }
      },
      checkRefundPolicy(policies) {
        // 判断用户填写的天数是否为连续天数
        for (let i in policies) {
          const item = policies[i];
          const nextItem = policies[~~i + 1];
          if (nextItem) {
            // check if continue
            const nextMaxDay = nextItem.maxDay || 0;
            if (item.minDay - nextMaxDay !== 1) {
              this.$message.warning('未能儲存！請寫連續天數');
              return false;
            }
            // check if maxday > minday
            if (item.minDay > item.ma) {
              this.$message.warning('未能儲存！請由小至大填寫');
              return false;
            }
          }
        }
        return true;
      },
      // 下一步
      onNext() {
        this.onSubmit(() => {
          this.$parent.nextStep();
        });
      },
      setOldDatas() {
        this.oldContent = this.content;
        this.oldFormData = JSON.stringify(this.formData);
      }
    }
  };
</script>
<style scoped lang="less">
  .self-funded{
    .ipt{
      width:100%;height:60px;padding:0 20px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    /deep/ .el-textarea .el-textarea__inner{height:342px;padding:20px;}
  }
  .compensation{
    width:100%;margin-top:20px;border:1px solid #f4f4f4;border-radius:4px 4px 0 0;
    thead{box-shadow:0 4px 4px #fbfbfb;}
    tbody{font-size:16px;}
    th,
    td{padding:9px 16px;vertical-align:middle;line-height:22px;text-align:left;}
    .proportion-txt{font-size:12px;font-weight:normal;}
    .ipt{
      width:80px;height:32px;padding:0;vertical-align:middle;text-align:center;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
      &[disabled]{background-color:#f4f4f4;cursor:not-allowed;}
    }
    .input-number{width:100px;vertical-align:middle;}
    .btn-add{color:#ff6f61;}
    .m-l{margin-left:8px;}
  }
  .refund-fee{
    .h3{margin-top:30px;text-align:center;font-size:18px;}
    .checkbox1{display:block;margin-right:6px;margin-top:2px;}
    .required{color:#f00;}
  }
  .prompt-content{
    padding-top:10px;text-align:left;border-top:1px solid #f4f4f4;
    /deep/ p{margin-top:10px;}
  }
</style>
