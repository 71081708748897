<template>
  <div class="main">
    <breadcrumb>
<!--      <breadcrumb-item :to="{name:'AdminProductList'}">{{$t('ProductManagement')}}</breadcrumb-item>-->
<!--      <breadcrumb-item>{{$t('AddProduct')}}</breadcrumb-item>-->
<!--      <breadcrumb-item>{{$t(activeName)}}</breadcrumb-item>-->
      <el-dropdown @command="changeStatus" v-if="productId" class="productDropdown">
        <a href="javascript:;">
          {{productInfo.isStatus|productStatus}} <i class="el-icon-caret-bottom"></i>
        </a>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="{productId:productId,status:'published'}"
                            v-if="['unpublished','obtained'].includes(productInfo.isStatus)">{{$t('Release')}}
          </el-dropdown-item>
          <el-dropdown-item :command="{productId:productId,status:'obtained'}"
                            v-if="productInfo.isStatus==='published'">{{$t('Obtained')}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </breadcrumb>
    <tabs class="product-tabs" :value="activeName" @tab-click="handleTabClick">
      <tab-pane :label="$t('BasicInformation')" name="BasicInformation">
        <basic-information :product-id.sync="productId"
                           :language="language"
                           :code.sync="productCode"
                           :name.sync="productTitle"
                           :isFlight="isFlight"
                           :competitionID="competitionID"
                           @next-step="nextPage" ref="b" v-on:getChild="getChild" v-on:getChildIsDay="getChildIsDay"/>
      </tab-pane>
      <tab-pane :label="$t('Multimedia')" name="Multimedia"
                :is-disabled="!productId"
                :disabled-msg="$t('PleaseStoreTheBasicInformationOfTheProductFirst')">
        <multimedia :product-id="productId"
                    :language="language"
                    :product-code="productCode"
                    :product-title="productTitle"
                    @next-step="nextPage"/>
      </tab-pane>
      <tab-pane :label="$t('Route')" name="Route"
                :is-disabled="!productId"
                :disabled-msg="$t('PleaseStoreTheBasicInformationOfTheProductFirst')">
        <tourist-route :product-id="productId"
                       :language="language"
                       :product-code="productCode"
                       :product-title="productTitle"
                       :isDay="isDay"
                       @reload-line="(list)=>lines=list"
                       @next-step="nextPage"/>
      </tab-pane>
<!--      <tab-pane :label="$t('PriceDate')" name="PriceDate"-->
<!--                :is-disabled="!lines.length"-->
<!--                :disabled-msg="!productId?$t('PleaseStoreTheBasicInformationOfTheProductFirst'):$t('PleaseSaveTheProductRouteFirst')">-->
<!--        <price-date :product-id="productId"-->
<!--                    :language="language"-->
<!--                    :product-code="productCode"-->
<!--                    :product-title="productTitle"/>-->
<!--      </tab-pane>-->
<!--      <tab-pane v-if="isFlight"-->
<!--                :label="$t('FlightInformation')" name="FlightInformation"-->
<!--                :is-disabled="!lines.length"-->
<!--                :disabled-msg="!productId?$t('PleaseStoreTheBasicInformationOfTheProductFirst'):$t('PleaseSaveTheProductRouteFirst')">-->
<!--        <flight-information :product-id="productId"-->
<!--                            :language="language"-->
<!--                            :product-code="productCode"-->
<!--                            :product-title="productTitle"/>-->
<!--      </tab-pane>-->
    </tabs>
  </div>
</template>
<script>
import Vue from 'vue';
import VueFroala from 'vue-froala-wysiwyg';
import Vuelidate from 'vuelidate';
import Breadcrumb from '../../components/admin/Breadcrumb';
import Tabs from '../../components/Tabs/Tabs';
import TabPane from '../../components/Tabs/TabPane';
import BasicInformation from './components/BasicInformation';
import TouristRoute from './components/TouristRoute';
import Multimedia from './components/Multimedia';
import {getProductLine, getProductInfo, updateProductStatus} from '../../api/user-product';
import {scrollToY} from '../../utils';

Vue.use(VueFroala);
Vue.use(Vuelidate);
export default {
  name: 'CompetitionProductAdd',
  data() {
    return {
      isFlight: true,
      isDay: false,
      activeName: '',
      productId: '',
      competitionID: '',
      language: '',
      productCode: '',
      productTitle: '',
      lines: [],
      priceList: [],
      productInfo: {}
    };
  },
  components: {
    Multimedia,
    Breadcrumb,
    Tabs,
    TabPane,
    BasicInformation,
    TouristRoute
  },
  mounted() {
    this.productId = this.$route.query.id || '';
    this.competitionID = this.$route.query.competitionID || '';
    this.language = this.$route.query.language || this.$i18n.locale;
    this.$nextTick(() => {
      this.activeName = this.$route.query.activeName || 'BasicInformation';
      this.getProductInfo();
    });
  },
  methods: {
    // 获取产品路线
    getProductLine() {
      getProductLine(this.language, this.productId).then(res => {
        this.lines = res.value || [];
      });
    },
    nextPage(name) {
      scrollToY();
      this.handleTabClick(name);
    },
    handleTabClick(activeName) {
      this.activeName = activeName;
      this.$router.replace({path: this.$route.fullPath, query: {activeName}});
    },
    getChild: function (val) {
      this.isFlight = this.$refs['b'].isFlight;
      this.isFlight = 0;
      this.isFlight = val;
    },
    getChildIsDay: function (val) {
      this.isFlight = this.$refs['b'].isFlight;
      this.isFlight = 0;
      this.isDay = val;
    },
    getProductInfo() {
      if (this.productId) {
        const loading = this.$loading({text: 'Loading'});
        getProductInfo(this.language, this.productId).then(res => {
          const value = res.value;
          this.productInfo = value;
          loading.close();
        }).catch(() => {
          loading.close();
        });
      }
    },
    // 修改发布状态
    changeStatus(command, instance) {
      const {productId, status} = command;
      const msg = {published: this.$t('Release'), obtained: this.$t('Obtained')}[status];
      this.$msgbox({
        title: this.$t('Prompt'),
        message: `確定要${msg}嗎？`,
        center: true,
        showClose: false,
        showCancelButton: true,
        lockScroll: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            console.log(productId);
            instance.confirmButtonLoading = true;
            updateProductStatus(productId, status).then(res => {
              instance.confirmButtonLoading = false;
              done();
              this.productInfo.isStatus = status;
            }).catch(() => {
              instance.confirmButtonLoading = false;
            });
          } else {
            done();
          }
        }
      }).then(action => {
      }).catch(() => {
      });
    }
  },
  watch: {
    productId: function (value) {
      this.getProductLine();
    },
    isFlight: function (val) {
      this.isFlight = val;
    }
  }
};
</script>
<style scoped lang="less">
.main{
  /deep/ .m-t{margin-top:20px;}
  /deep/ .actions{
    margin-top:40px;text-align:center;
    .button-primary{margin-right:16px;}
  }
  /deep/ .el-textarea__inner{height:160px;padding:10px;}
}
.product-tabs{
  /deep/ .tab-nav .item{width:20%;}
}
.productDropdown{
  float: right;
  border-radius: 4px;
  border: 2px solid #ff6f61;
  padding: 0px 10px;
  font-weight: bold;
  background: white;
}
</style>
