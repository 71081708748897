<template>
  <div class="schedule" v-if="days.length">
    <div>
      <span class="vam">{{$t('Part')}}：</span>
      <el-select class="select w1" v-model="daysTotal" @change="dayChange" size="small" placeholder="">
        <el-option v-for="i of evenNumbers"
                   :key="i"
                   :label="i"
                   :value="i">
        </el-option>
      </el-select>
    </div>
    <div class="days">
      <schedule-item v-for="(item,index) of days"
                     :index="index"
                     :item="days[index]"
                     :days-length="days.length"
                     @swap-arr="swapArr"
                     @delete-day="deleteDay"
                     :key="item.travelId||index"/>
    </div>
    <div class="actions">
      <button class="button button-primary" type="button" @click="onSubmit">{{$t('Save')}}</button>
      <button class="button" type="button" @click="onNext">{{$t('NextStep')}}</button>
    </div>
  </div>
</template>
<script>
  import {getProductSchedule, saveProductSchedule, deleteProductSchedule} from '../../../api/user-product';
  import ScheduleItem from './ScheduleItem';

  export default {
    name: 'Schedule',
    components: {ScheduleItem},
    props: {
      productId: String,
      language: String,
      lineId: String
    },
    data() {
      return {
        daysTotal: 1,
        days: [],
        oldDays: []
      };
    },
    computed: {
      evenNumbers: function () {
        let length = [];
        for (let i = 1; i <= 30; i++) {
          if (i >= this.daysTotal) {
            length.push(i);
          }
        }
        return length;
      },
      dayObj: function () {
        return {
          lineId: this.lineId,
          productId: this.productId,
          languageType: this.language,
          title: '',
          content: '',
          traffic: '',
          room: '',
          breakfast: '',
          chineseFood: '',
          dinner: '',
          other: '',
          resourceUrl: [],
          fileList: []
        };
      }
    },
    created() {
      this.getProductSchedule();
    },
    destroyed() {
      if (JSON.stringify(this.days) !== this.oldDays) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.UnsavedPrompt'),
          confirmButtonText: this.$t('Save'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              this.onSubmit(() => {
                instance.confirmButtonLoading = false;
                done();
              }, true);
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      }
    },
    methods: {
      // 获取产品路线行程列表
      getProductSchedule() {
        getProductSchedule(this.language, this.lineId).then(res => {
          let days = (res.value || []).map(item => {
            item.fileList = item.resourceUrl ? item.resourceUrl.split(';').map(imgItem => ({url: imgItem})) : [];
            return item;
          });
          if (!days.length) {
            days.push({...this.dayObj});
          }
          this.days = days;
          this.oldDays = JSON.stringify(days);
          this.daysTotal = days.length;
        });
      },
      dayChange(day) {
        const daysLength = this.days.length;
        for (let i = 0; i < day - daysLength; i++) {
          this.days.push({...this.dayObj});
        }
      },
      swapArr(day) {
        const indexArr = day.split('-');
        const oldIndex = Number(indexArr[0]);
        const nextIndex = Number(indexArr[1]) - 1;
        const days = [...this.days];
        if (oldIndex > nextIndex) {
          days.splice(nextIndex, 0, days[oldIndex]);
          days.splice(oldIndex + 1, 1);
        } else {
          days.splice(nextIndex + 1, 0, days[oldIndex]);
          days.splice(oldIndex, 1);
        }
        this.days = days;
      },
      // 删除某一天行程
      deleteDay(index) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.delete'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              this.onDeleteDay(index, instance, done);
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      },
      onDeleteDay(index, instance, done) {
        const travelId = this.days[index].travelId;
        if (!travelId) {
          this.deleteDaySuccess(index, instance, done);
        } else {
          deleteProductSchedule([travelId]).then(res => {
            this.deleteDaySuccess(index, instance, done);
          }).catch(() => {
            instance.confirmButtonLoading = false;
          });
        }
      },
      deleteDaySuccess(index, instance, done) {
        this.days.splice(index, 1);
        if (!this.days.length) {
          this.$nextTick(() => {
            this.days.push({...this.dayObj});
          });
        }
        this.daysTotal = this.days.length || 1;
        instance.confirmButtonLoading = false;
        done();
      },
      // 存储
      onSubmit(callback, isForcedSave) {
        let errMsg = '';
        const data = this.days.map((item, index) => {
          const fileList = item.fileList.map(fileItem => fileItem.url);
          if (fileList.filter(imgItem => !imgItem).length) {
            errMsg = '上传图片中';
          }
          item.resourceUrl = fileList.join(';');
          // delete item.fileList;
          // if (!isForcedSave && (!item.title || !item.content)) {
          //   errMsg = this.$t('Please complete the information before submitting');
          // }
          item.days = index + 1;
          return item;
        });

        if (errMsg && !isForcedSave) {
          this.$message.warning(errMsg);
          return false;
        }

        saveProductSchedule(data).then(res => {
          this.oldDays = JSON.stringify(this.days);
          !isForcedSave && this.$message.success(this.$t('SaveSuccessfully'));
          if (typeof callback === 'function') {
            callback();
          }
        });
      },
      // 下一步
      onNext() {
        this.onSubmit(() => {
          this.$parent.nextStep();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .schedule{
    font-size:16px;
    .select,
    /deep/ .select{
      width:120px;vertical-align:top;
      &.w1{width:80px;vertical-align:middle;}
    }
  }
</style>
