<template>
  <div class="multimedia">
    <title-code :language="language" :product-id="productId" :product-title="productTitle" :product-code="productCode"/>
    <div style="margin-top:30px">
      <button class="button button-primary m-r" @click="dialogAddVisible=true">{{$t('AddPicture')}}</button>
      <button class="button m-r" :disabled="!selectedList.length" @click="showEditDialog">
        {{$t('BatchEdit')}}
      </button>
      <button class="button" :disabled="!selectedList.length" @click="deletePicture">{{$t('BatchDeletion')}}
      </button>
    </div>
    <checkbox class="btn-select-all" v-model="selectAll">
      <div class="checkbox-label">{{$t('SelectAll')}}</div>
    </checkbox>
    <div class="picture-list cl">
      <div class="item" v-for="item of pictureList" :key="item.mediaId">
        <div class="cover">
          <img class="img" :src="item.photoUrl" alt=""/>
        </div>
        <div class="tit">{{item.title}}</div>
        <div class="action cl">
          <checkbox class="fl" v-model="item.isSelected"/>
          <div class="fr">
            <a class="btn-item btn-default-img" :class="{active:item.mediaId === bigPictureId}">
              {{$t('DefaultBigPicture')}}
            </a>
            <a class="btn-item" @click="editMediaItem(item)">
              <i class="icons i-edit"></i>
            </a>
            <a class="btn-item" @click="onDeletePicture([item.mediaId])">
              <i class="icons i-ashcan"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <empty v-if="!pictureList.length" :title="$t('NoData')"/>
    <!--<div class="actions">
      <button class="button" type="button" @click="onNext">{{$t('NextStep')}}</button>
    </div>-->
    <multimedia-add-dialog :product-id="productId"
                           :language="language"
                           :show.sync="dialogAddVisible"
                           @reload-list="getProductMediaList"/>
    <multimedia-edit-dialog :product-id="productId"
                            :language="language"
                            :list="selectedList"
                            :item.sync="currentItem"
                            :show.sync="dialogEditVisible"
                            :big-picture-id.sync="bigPictureId"
                            @reload-list="getProductMediaList"/>
  </div>
</template>
<script>
import Checkbox from '../../../components/Checkbox';
import Empty from '../../../components/Empty';
import TitleCode from './TitleCode';
import MultimediaAddDialog from './MultimediaAddDialog';
import MultimediaEditDialog from './MultimediaEditDialog';
import {getProductMediaList, deleteProductMedia, setProductMediaDefaultImg} from '../../../api/user-product';

export default {
    name: 'Multimedia',
    props: {
      productId: String,
      language: String,
      productCode: String,
      productTitle: String
    },
    data() {
      return {
        pictureList: [],
        dialogEditVisible: false,
        dialogAddVisible: false,
        currentItem: null,
        selectAll: false,
        bigPictureId: ''
      };
    },
    computed: {
      selectedList: function () {
        return this.pictureList.filter(item => item.isSelected);
      }
    },
    components: {TitleCode, Empty, MultimediaEditDialog, Checkbox, MultimediaAddDialog},
    created() {
      this.getProductMediaList();
    },
    methods: {
      // 获取产品路线多媒体列表
      getProductMediaList() {
        getProductMediaList(this.language, this.productId).then(res => {
          this.selectAll = false;
          this.pictureList = (res.value || []).map(item => {
            item.isSelected = false;
            return item;
          });
          // 默认大图id
          const bigPictureItem = this.pictureList.find(item => item.isDefault === 'Y');
          if (bigPictureItem) {
            this.bigPictureId = bigPictureItem.mediaId;
          }
        });
      },
      // 显示修改弹窗
      showEditDialog() {
        // if (this.selectedList.length > 5) {
        //   this.$message.warning(this.$t('UpToFiveAtATime'));
        //   return false;
        // }
        this.dialogEditVisible = true;
      },
      // 编辑单个多媒体
      editMediaItem(item) {
        this.currentItem = item;
        this.dialogEditVisible = true;
      },
      // 删除图片
      deletePicture() {
        // if (!this.selectedList.length) {
        //   this.$message.warning('请勾选要删除的图片');
        //   return false;
        // }
        const ids = this.selectedList.map(item => item.mediaId);
        this.onDeletePicture(ids);
      },
      onDeletePicture(data) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.delete'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              deleteProductMedia(data).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.getProductMediaList();
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      },
      // 设置默认大图
      setDefaultImg(mediaId) {
        setProductMediaDefaultImg(mediaId).then(res => {
          this.pictureList = this.pictureList.map(item => {
            item.isDefault = item.mediaId === mediaId;
            return item;
          });
        });
      },
      // 下一步
      onNext() {
        this.$emit('next-step', 'TouristRoute');
      }
    },
    watch: {
      selectAll: function (value) {
        this.pictureList = this.pictureList.map(item => {
          item.isSelected = value;
          return item;
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .picture-list{
    .item{
      position:relative;float:left;width:220px;margin:20px 20px 0 0;overflow:hidden;box-shadow:0 1px 5px 0 rgba(255, 115, 101, 0.20);border-radius:4px;
      &:nth-child(4n){margin-right:0;}
    }
    .cover{height:150px;overflow:hidden;}
    .tit{padding:0 10px;text-align:center;line-height:40px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
    .action{position:absolute;top:0;right:0;left:0;padding:5px 10px;line-height:1;background-color:rgba(0, 0, 0, 0.3);}
    .btn-item{display:inline-block;margin-left:10px;vertical-align:top;cursor:pointer;}
    .btn-default-img{
      line-height:16px;vertical-align:middle;color:#fff;font-size:12px;
      &.active{color:#ff6f61;}
    }
    .i-edit{width:16px;height:16px;background-image:url(../../../assets/images/icon/pen-white.png);}
    .i-ashcan{width:16px;height:16px;background-image:url(../../../assets/images/icon/ashcan-white.png);}
  }
  .btn-select-all{
    margin-top:20px;
    /deep/ .checkbox-label{font-size:16px;}
  }
</style>
