<template>
  <div class="visa-wrap">
    <div class="tabs-nav cl">
      <div class="item"
           v-for="(item,index) of list"
           :class="{'is-edit':item.isEdit,active:index===listIndex}"
           :key="index"
           @click="changeIndex(index)"
           @dblclick="item.isEdit=true">
        <input type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
               v-focus
               v-model.trim="item.location"
               @keyup.enter="item.isEdit=false"
               @blur="item.isEdit=false"
               v-if="item.isEdit"/>
        <template v-else>{{item.location}}</template>
        <div class="btns cl">
          <a class="btn-edit" @click.stop="item.isEdit=true"></a>
          <a class="btn-del" @click.stop="deleteItem(index)"></a>
        </div>
      </div>
      <a class="btn-add" @click="addItem">
        <img class="vam" src="../../../assets/images/icon/plus-circle-red.png"/>
        <span class="vam"> {{$t('Add a visa location')}}</span>
      </a>
    </div>
    <template v-show="list.length">
      <div class="m-t">
<!--        <froala :config="froalaConfig" v-model="list[listIndex].content"/>-->
        <editor-elem v-model="list[listIndex].content"/>
      </div>
<!--      <div  id="editor"></div>-->
      <div class="actions">
        <button class="button button-primary" type="button" @click="onSubmit">{{$t('Save')}}</button>
        <button class="button" type="button" @click="onNext">{{$t('NextStep')}}</button>
      </div>
    </template>
  </div>
</template>
<script>
  import editor from '../../../mixins/editor';
  import {getProductLineVisaList, saveProductLineVisa} from '../../../api/user-product';
  import editorElem from '../../../components/EditorBar';
  export default {
    name: 'Visa',
    directives: {
      focus: {
        inserted: function (el) {
          el.focus();
        }
      }
    },
    props: {
      productId: String,
      language: String,
      lineId: String
    },
    data() {
      return {
        list: [
          {
            productId: this.productId,
            lineId: this.lineId,
            languageType: this.language,
            location: '',
            content: '',
            isEdit: true}
        ],
        listIndex: 0,
        oldList: [],
        newContent: ''
      };
    },
    components: {editorElem},
    mixins: [editor],
    created() {
      this.getProductLineVisaList();
    },
    destroyed() {
      if (JSON.stringify(this.list) !== this.oldList) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.UnsavedPrompt'),
          confirmButtonText: this.$t('Save'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              this.onSubmit(() => {
                instance.confirmButtonLoading = false;
                done();
              }, true);
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      }
    },
    methods: {
      changeIndex(index) {
        console.log(index);
        this.listIndex = index;
        console.log(this.listIndex);
      },
      // 新增签证地点
      addItem() {
        if (this.list.find(item => !item.location)) {
          for (let item of this.list) {
            if (!item.location) {
              item.isEdit = true;
              break;
            }
          }
          return false;
        }
        this.list.push({
          productId: this.productId,
          lineId: this.lineId,
          languageType: this.language,
          location: '',
          content: '',
          isEdit: true
        });
        this.listIndex = this.list.length - 1;
      },
      // 删除签证地址
      deleteItem(index) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.delete'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              this.list.splice(index, 1);
              if (!this.list.length) {
                this.addItem();
              }
              if (this.listIndex > this.list.length - 1) {
                this.listIndex = 0;
              }
              done();
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      },
      // 获取产品路线签证须知
      getProductLineVisaList() {
        getProductLineVisaList(this.language, this.lineId).then(res => {
          this.list = (res.value || []).map(item => {
            item.isEdit = false;
            return item;
          });
          this.oldList = JSON.stringify(this.list);
        });
      },
      // 储存
      onSubmit(callback, isForcedSave) {
        saveProductLineVisa(this.list).then(res => {
          this.oldList = JSON.stringify(this.list);
          !isForcedSave && this.$message.success(this.$t('SaveSuccessfully'));
          if (typeof callback === 'function') {
            callback();
          }
        });
      },
      // 下一步
      onNext() {
        this.onSubmit(() => {
          this.$parent.nextStep();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .tabs-nav{
    .item{
      position:relative;float:left;width:132px;height:40px;margin-top:8px;margin-right:8px;padding:0 8px;overflow:hidden;line-height:38px;white-space:nowrap;text-overflow:ellipsis;text-align:center;color:#999;cursor:pointer;border:1px dashed #ccc;border-radius:4px;user-select:none;
      &:hover{
        border-color:#ff6f61;
        .btns{display:block;}
      }
      &.active{color:#ff6f61;border-color:#ff6f61;}
      &.is-edit{
        border-color:#ff6f61;
        .btns{visibility:hidden;}
      }
      input{width:100%;height:38px;text-align:center;border:none;background-color:transparent;}
    }
    .btn-add{float:left;margin-top:8px;margin-left:8px;line-height:40px;cursor:pointer;color:#ff6f61;}
  }
  .btns{
    display:none;position:absolute;top:0;right:0;
    > a{
      float:left;width:16px;height:16px;opacity:0.8;cursor:pointer;
      &:hover{opacity:1;}
    }
  }
  .btn-del{background:url(../../../assets/images/icon/ashcan-red.png) no-repeat 0 0;}
  .btn-edit{background:url(../../../assets/images/icon/pen-red.png) no-repeat 0 0;}
</style>
